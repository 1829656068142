import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Gallery Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/gallery/"
    metaDescription="Uizard hosts a growing list of professionally-designed gallery templates that are all fully editable and customizable to your need and liking."
    description="
    h2:Perfect for image-heavy designs
    <br/>
    Drag-and-drop pre-made gallery templates for your apps and websites.
    <br/>
    Simply drag-and-drop Uizard's different gallery templates to design your software.
    <br/>
    h3:Fully editable and customizable gallery templates reflecting the latest design trends
    <br/>
    Uizard hosts a growing list of professionally-designed gallery templates that are all fully editable and customizable to your need and liking.
    The templates embody the latest design trends and also always reflect your chosen theme.
    <br/>
    h3:No need to worry about gallery designs
    <br/>
    The gallery component template in Uizard are extremely easy to use and always automatically create a nice way to organize and present photos, images, and visuals.
    <br/>
    With Uizard, there's no need to worry about designing a gallery from scratch!
    "
    pages={[
      "Product photo gallery card",
      "Gallery woven title",
      "Gallery woven",
      "Large carousel",
      "Big gallery masonry",
      "Big photo gallery masonry columns",
      "Video gallery",
      "Image gallery",
      "Carousel with call to action",
      "Carousel with text",
      "Details and thumbnails",
      "Small gallery masonry columns",
      "Small gallery quilted variable sizes",
      "Small gallery tiles",
      "Small gallery masonry",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing image galleries"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing image galleries"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing image galleries"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing image galleries"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing image galleries"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/component-templates/gallery/gallery_1.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/component-templates/gallery/gallery_2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/component-templates/gallery/gallery_3.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/component-templates/gallery/gallery_4.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/component-templates/gallery/gallery_5.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
